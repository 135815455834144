<template>
  <div>
    <div class="checkout-cart">
      <div class="checkout-cart__title">
        <h4 class="text-white m-0 py-3 py-md-4">Jouw zorgpakket</h4>
      </div>
      <div class="checkout-cart__products">
        <template v-if="this.CART.length > 0">
          <div class="checkout-cart__product-list">
            <div class="checkout-cart__product">
              <span class="checkout-cart__item-highlight">Overzicht</span>
            </div>
            <div class="checkout-cart__product"
                 v-for="product in this.CART"
                 :key="product.id">
              <div class="checkout-cart__product-info">
                <div class="checkout-cart__product-title">
                  {{ product.title }}
                </div>
                <div v-if="!disableTotal"
                     class="checkout-cart__product-cost d-flex align-items-center">
                  {{ getFormattedCost(getProductCost(product.cost, product.careCost)) }}
                  <div
                    v-if="showDeleteButton"
                    @click="productIsMandatory(product) ? null : removeFromCart(product)"
                    class="checkout-cart__remove"
                    :class="{ 'disabled': productIsMandatory(product) }"
                    aria-hidden="true"
                  ></div>
                </div>
              </div>
              <div v-if="showProductType && product.type"
                   class="checkout-cart__product-description">
                {{ product.type.charAt(0).toUpperCase() + product.type.slice(1) }}
              </div>
            </div>
            <div v-if="showDeliveryInfoSection" class="checkout-cart__delivery-info">
              <div class="checkout-cart__delivery-info-title">
                {{ CART_DELIVERY_INFO.title }}
              </div>
              <div class="checkout-cart__delivery-info-value">
                {{ CART_DELIVERY_INFO.value }}
              </div>
            </div>
          </div>
          <DonationCartItem
            v-if="isDonationEnable"
            :is-dynamic="isDonationSet"
          />
          <!-- Total-->
          <template v-if="!disableTotal">
            <div class="checkout-cart__summ">
              <b>Totaal</b>
              <b class="checkout-cart__summ-amount">{{ getFormattedCost(CART_SUM) }}</b>
            </div>
          </template>

          <field-group-component
              v-if="showDeleteButton"
              class="checkout__field-group text-small checkout-cart__care">
            <label class="input-radio checkout__radio-button">
              <input type="checkbox" v-model="care" :value="true">
              <span class="input-radio__helper input-radio__helper_min input-checkbox__helper">
                <span class="input-radio__text">
                  Ik heb een zorgverzekering in Nederland
                </span>
              </span>
            </label>
          </field-group-component>
          <div v-if="showDeleteButton && agreementLink"
               class="checkout-cart__radio-buttons checkout-cart__radio-buttons_modifier mt-3">
            <label class="input-radio checkout__radio-button">
              <input type="checkbox" v-model="agreement" :value="false">
              <span class="input-radio__helper input-radio__helper_min input-checkbox__helper">
                <span class="input-radio__text">
                  Akkoord&nbsp;
                </span>
              </span>
            </label>
            <div>
            <field-group-component-popup
                class="custom__field-group"
                v-if="showDeleteButton && agreementLink"
                :link="agreementLink"
                :heading="informedconsentHeading"
                :cont="informedconsentCont"
                title=" {link} *">
            </field-group-component-popup>
            </div>
          </div>

          <div class="checkout-cart__btn-wrapper">
            <template v-if="!disableTotal">
              <slot></slot>
              <a v-if="cartAction"
                 :href="action"
                 class="btn btn-primary btn-block"
                 :class="{'disabled': disableButton}">
                {{ actionText }}
              </a>
            </template>
          </div>

          <div class="checkout-cart__guarantee">
            <div class="checkout-cart__guarantee-item">
              <div class="package-export checkout-cart__guarantee-item-icon"></div>
              <div>Inclusief test, advies en behandeling</div>
            </div>
            <div class="checkout-cart__guarantee-item">
              <div class="door-enter checkout-cart__guarantee-item-icon"></div>
              <div>Op locatie? Na 90 minuten al uitslag</div>
            </div>
          </div>

          <div
              v-if="isDonationEnable"
              class="checkout-cart__donation"
          >
            <DonationCart />
          </div>
        </template>
        <div v-else class="checkout-cart__empty">
          Geen zorgproduct gekozen
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DonationCart from '@/components/widgets/DonationCart';
/* eslint-disable import/extensions */
import DonationCartItem from '@/components/widgets/DonationCart/DonationCartItem';
import getGtmProduct from '../helpers/gtmProduct';
import dataValidationMixin from '@/mixins/dataValidationMixin';

export default {
  name: 'CartComponent',

  components: {
    DonationCartItem,
    DonationCart,
  },

  mixins: [dataValidationMixin],

  data() {
    return {
      showDeliveryInfoSection: false,
    };
  },
  props: {
    cartInfo: {
      type: Boolean,
      default: false,
    },
    actionText: {
      type: String,
      default: 'Ik wil betalen',
    },
    action: {
      type: String,
      default: '/checkout',
    },
    cartAction: {
      type: Boolean,
      default: false,
    },
    disableTotal: {
      type: Boolean,
      default: false,
    },
    showProductType: {
      type: Boolean,
      default: true,
    },
    showDeleteButton: {
      type: Boolean,
      default: false,
    },
    clearCart: {
      type: Boolean,
      default: false,
    },
    consultAction: {
      type: String,
      default: '/consult',
    },
    agreementLink: {
      type: Object,
      default: null,
    },
    informedconsentCont: {
      type: String,
      default: '',
    },
    informedconsentHeading: {
      type: String,
      default: '',
    },
    isDonationSet: {
      type: Boolean,
      default: false,
    },
    isDonationEnable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getProductCost(cost, careCost) {
      return this.care ? careCost : cost;
    },
    getFormattedCost(cost) {
      if (cost % 1 === 0) {
        return `€${cost},-`;
      }
      return `€${cost}`.replace('.', ',');
    },
    removeFromCart(product) {
      if (!this.productIsMandatory(product)) {
        this.$store.commit('REMOVE_FROM_CART', product);
      }
    },
    toggleBloodContext(val) {
      if (typeof this.$parent.$refs.blood !== 'undefined') {
        if (val) {
          this.$parent.$refs.blood.classList.add('d-none');
        } else {
          this.$parent.$refs.blood.classList.remove('d-none');
        }
      }
    },
    productIsMandatory(product) {
      if (['O', 'U'].includes(product.letter)) {
        return this.CART.some((item) => item.letter === 'V');
      }
      return false;
    },
  },
  watch: {
    CART_DELIVERY_INFO(value) {
      this.showDeliveryInfoSection = 'title' in value && 'value' in value;
    },

    IS_BLOOD_TEST_EXISTS(newVal) {
      this.toggleBloodContext(newVal);
    },

    // TODO Remove after getting data from backend
    IS_DISABLE_HOME_VISIT(value) {
      if (value) {
        this.$store.commit('REMOVE_FROM_CART', {
          id: 0,
        });
      }
    },
  },
  mounted() {
    this.redirectEmptyAnamnesis(this.ANAMNESIS);

    this.toggleBloodContext(this.IS_BLOOD_TEST_EXISTS);
    if (this.clearCart) {
      const params = (new URL(document.location)).searchParams;
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: 'purchase',
        ecommerce: {
          transaction_id: params.get('payment'),
          value: `${this.CART_SUM}.00`,
          currency: 'EUR',
          items: this.CART.map((product, i) => getGtmProduct(product, i)),
        },
      });
    }
  },
  computed: {
    ...mapGetters([
      'CART',
      'CART_SUM',
      'IS_BLOOD_TEST_EXISTS',
      'IS_DISABLE_HOME_VISIT',
      'CART_DELIVERY_INFO',
      'ANAMNESIS',
    ]),
    care: {
      get() {
        return this.$store.state.care;
      },
      set(value) {
        this.$store.commit('SET_CARE', value);
      },
    },
    agreement: {
      get() {
        return this.$store.state.agreement;
      },
      set(value) {
        this.$store.commit('SET_AGREEMENT', value);
      },
    },
    isOnlyDeliveryItem() {
      return this.CART.filter(Boolean).length === 1 && this.CART.find((item) => item.id === 0);
    },
    disableButton() {
      return (!this.agreement) || this.care === null || this.isOnlyDeliveryItem;
    },
  },
};
</script>

<style>
  .checkout-cart__delivery-info {
    display: flex;
    justify-content: space-between;
    font-size: 1.2rem;
  }

  .checkout-cart__delivery-info-value {
    color: #02ba71;
  }

  .checkout-cart__radio-buttons_modifier {
    display: flex;
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    .checkout-cart__radio-buttons_modifier {
      flex-direction: column;
    }
  }

  @media (max-width: 385px) {
    .checkout-cart__radio-buttons_modifier {
      flex-direction: column;
    }
  }

  .checkout-cart__radio-buttons_modifier .form-group__title {
    line-height: 1.5rem;
    font-size: 1rem;
    margin-bottom: 0 !important;
  }

  .checkout-cart__radio-buttons_modifier .form-group__append-title {
    color: var(--Foundation-Red-red-700, #B03A23);
  }

  .checkout-cart__radio-buttons_modifier .results-products__item-add_to_cart,
  .checkout-cart__radio-buttons_modifier .results-products__item-add_to_cart:hover {
    color: black!important;
    text-decoration: underline;
  }
</style>

<style scoped>
  .checkout-cart__remove.disabled {
    opacity: 0;
    pointer-events: none;
  }
</style>
