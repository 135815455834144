const dataValidationMixin = {
  methods: {
    redirectEmptyAnamnesis(amnesis) {
      if (!amnesis || amnesis.length === 0) {
        window.location.href = 'consult';
      }
    },
  },
};

export default dataValidationMixin;
